<template>
  <b-card title="Add Product Hararqy">
    <b-row>
      <b-col cols="12">
        <b-form @submit.prevent="">
          <b-form-group label="Product Hierarchy Number (First 5 digits)">
            <b-row>
              <b-col cols="12" md="8">
                <b-form-input
                  v-model="phNumber"
                  type="text"
                  placeholder="Enter PH Number (First 5 digits)"
                  required
                ></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>

          <b-form-group label="Word" >
            <b-row>
              <b-col cols="12" md="8">
                <b-form-input
                  v-model="word"
                  type="text"
                  placeholder="Enter Word"
                ></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-button @click="addPostal" type="submit" variant="success"
            >Save</b-button
          >
        </b-form>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "@/axios";
import { sleep }from '@/utils/utils.js'
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  components: {
    'v-select': vSelect,
  },
  data() {
    return {
      search:'',
      phNumber: "",
      word: "",
      status: "false",
      plant: 1000,
      description:""
    };
  },
  mounted() {
  },
  methods: {
    ...mapActions({
      // getItems: "item/getItems",
      saveBundle: "item/addItemBundle",
    }),
    // searchItemModel(){
    //   axios
    //     .post("/sales-and-purchase/item-catalogue/search", {
    //       itemModel: this.search,
    //     })
    //     .then((res) => {
    //       this.itemModel = res.data.data.itemModel
    //       this.description = res.data.data.description

    //       this.$bvToast.toast("Item Model Found", {
    //         title: "Success",
    //         variant: "success",
    //         solid: true,
    //       });
    //     })
    //     .catch((error) => {
    //       const errorMessage = error.response.data.errors[0].message|| "Error Item Model Not Found";
    //       console.log(errorMessage);
    //       this.$bvToast.toast(errorMessage, {
    //         title: "Fail",
    //         variant: "danger",
    //         solid: true,
    //       });
    //     });
    // },
    addPostal() {
      axios
        .post("/sales-and-purchase/drcc/phmap", {
          phNumber: this.phNumber,
          word: this.word,
        })
        .then(async(response) => {
          
          this.$bvToast.toast("Successfully Add Product PH", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          await sleep(1000)
          this.$router.push({ name: "master-ph" });
        })
        .catch((error) => {
          const errorMessage = error.response.data.errors[0].message|| "Error Found POS Code";
          console.log(errorMessage);
          this.$bvToast.toast(errorMessage, {
            title: "Fail",
            variant: "danger",
            solid: true,
          });
        });
    },
  },
  computed: {
    // ...mapState({
    //   itemCatalogue: (state) => {
    //     return state.item.items.map((i) => {
    //       return { label: `${i.description} - ${i.itemModel} `, ...i }
    //       //return Object.assign({ value: i.itemModel, text: `${i.itemModel}` });
    //     });
    //   },
    // })
  },
};
</script>
<style scoped>
@media (max-width: 761px) {
  .hide-on-desktop { display: none !important; }
  .show-on-desktop {display: inline !important;}
}

@media (min-width: 762px) {
  .hide-on-mobile { display: none !important; }
}
</style>
